import { Col, Container, Row } from "react-bootstrap"
import InviteToTeam from "./InviteToTeam"
import LeaveTeam from "./LeaveTeam"
import LogLocation from "./LogLocation"
import RaceValid from "./RaceValid"
import useConfig from "../../hooks/useConfig"
import useAuth from "../../hooks/useAuth"

const TeamMembershipPage = ({setDisplay}) => {

    const { config } = useConfig();
    const { auth } = useAuth();

    return (
        <Container className="team-membership-container">
            <Row>
                <Col md className="me-3">
                    <Row className="mt-3">
                        <Col md className="invite-to-team-col form-col"><InviteToTeam /></Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md className="form-col"><RaceValid /></Col>
                    </Row>
                </Col>
                {
                    // check that the team is ready to race, and the race is curr ongoing
                    auth?.team?.raceValid?.valid && config?.race_status?.status === 2 ?
                        <Col md className="log-location-col form-col mt-3"><LogLocation setDisplay={setDisplay} /></Col>
                    : undefined
                }
            </Row>
            <Row className="mt-3">
                <Col md className="leave-team-col form-col"><LeaveTeam setDisplay={setDisplay} /></Col>
            </Row>
        </Container>
    )
}

export default TeamMembershipPage;