import { useEffect, useState } from "react";
import FormInput from "./FormInput";
import '../styles/UserSettings.css'
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import useLogout from "../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { regex } from "../constants/regex";
import { Container, Form, Row, Col, Button, Alert } from "react-bootstrap";
import Gender from "../classes/Gender";
import escapeRegExp from "../functions/escapeRegExp";

const UserSettings = () => {

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const logout = useLogout();
    const navigate = useNavigate();

    const [errorMessageDetails, setErrorMessageDetails] = useState('');
    const [errorMessagePass, setErrorMessagePass] = useState('');
    const [successMessageDetails, setSuccessMessageDetails] = useState('');
    const [validatedDetails, setValidatedDetails] = useState(false);
    const [validatedPass, setValidatedPass] = useState(false);
    const [originalValues, setOriginalValues] = useState({
        full_name: '',
        user_name: '',
        gender: '',
        email: '',
        phone_number: ''
    });
    const [values, setValues] = useState({
        ...originalValues,
        password_old: '',
        password_new: '',
        password_conf: ''
    });
    const inputs = [
        {
            id: 1,
            name: 'full_name',
            type: 'text',
            edit: true,
            placeholder: 'Full Name',
            label: 'Full Name',
            pattern: regex.full_name,
            errorMessage: "Only alphabetic characters, '-' and '.'"
        },
        {
            id: 2,
            name: 'user_name',
            type: 'text',
            edit: true,
            placeholder: 'Username',
            label: 'Username',
            pattern: regex.user_name,
            errorMessage: "Between 4 and 16 alphanumeric characters, '_' and '-'"
        },
        {
            id: 3,
            name: 'gender',
            type: 'select',
            edit: true,
            label: 'Gender',
            errorMessage: 'Please select a gender',
            values: Gender.values,
            selectnullval: 'Select Gender'
        },
        {
            id: 4,
            name: 'age',
            type: 'number',
            edit: true,
            label: 'Age',
            errorMessage: 'Please enter a valid age'
        },
        {
            id: 5,
            name: 'email',
            type: 'email',
            edit: true,
            placeholder: 'Email',
            label: 'Email',
            pattern: regex.email_standrews,
            errorMessage: 'Please enter a valid @st-andrews.ac.uk email address'
        },
        {
            id: 6,
            name: 'phone_number',
            type: 'text',
            edit: true,
            placeholder: 'Telephone',
            label: 'Telephone',
            pattern: regex.phone_number
        }
    ];
    const passwordInputs = [
        {
            id: 7,
            name: 'password_old',
            type: 'password',
            placeholder: 'Current Password',
            label: 'Current Password',
            pattern: regex.password,
            errorMessage: "At least 8 characters, with an uppercase, lowercase, digit, and special character (#?!@$%^&*-)"
        },
        {
            id: 8,
            name: 'password_new',
            type: 'password',
            placeholder: 'New Password',
            label: 'New Password',
            pattern: regex.password,
            errorMessage: "At least 8 characters, with an uppercase, lowercase, digit, and special character (#?!@$%^&*-)"
        },
        {
            id: 9,
            name: 'password_conf',
            type: 'password',
            placeholder: 'Confirm Password',
            label: null,
            pattern: new RegExp(escapeRegExp(values.password_new)),
            errorMessage: 'Must match the password field'
        }
    ];

    const onChange = (e) => {
        setValues({...values, [e.target.name]:e.target.value})
    }

    const handleSubmitDetails = async (e) => {
        setErrorMessageDetails('');
        setSuccessMessageDetails('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidatedDetails(true);
            return;
        }

        var changes = [];
        inputs.forEach(input => {
            if (values[input.name] != originalValues[input.name]) {
                changes.push({property: input.name, value: values[input.name]});
            }
        });
        if (changes.length > 0) {
            try {
                const response = await axiosPrivate.post(
                    '/users/update',
                    JSON.stringify({changes: changes})
                )

                // change original values to reflect what is now stored in the db
                setOriginalValues({
                    user_name: response.data.user_name,
                    full_name: response.data.full_name,
                    email: response.data.email,
                    phone_number: response.data.phone_number
                });
                setSuccessMessageDetails('Successfully updated!');
            }
            catch (err) {
                console.log(`Error ${err.response.status}: ${err.response?.data}`);
                if (err.response?.data?.reasons) {
                    setErrorMessageDetails(err.response.data.reasons.join(' '));
                }
                else {
                    setErrorMessageDetails(err.response?.data);
                }
            }
        }
    } 

    const handleSubmitPassword = async (e) => {
        setErrorMessagePass('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidatedPass(true);
            return;
        }

        // check that passwords are different
        if (values.password_old === values.password_new) {
            console.log("New password must be different to old password.");
            setErrorMessagePass("New password must be different to old password.");
            return;
        }

        try {
            const response = await axiosPrivate.post(
                '/users/change-password',
                JSON.stringify({password_old: values.password_old, password_new: values.password_new}),
                {}
            )

            // logout user
            await logout();
            navigate('/login');
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
            if (err.response?.data?.reasons) {
                setErrorMessagePass(err.response.data.reasons.join(' '));
            }
            else {
                setErrorMessagePass(err.response?.data);
            }
        }
    } 

    const loadValues = async () => {
        try {
            const response = await axiosPrivate.get('/users/me');
            if (response?.data != undefined) {
                const { _id, _v, admin_privileges, ...data} = response.data;
                setOriginalValues(data);
                setValues({
                    ...values,
                    ...data
                });
            }
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
        }
    }

    useEffect(() => {
        loadValues();
    }, [])

    return (
        <Container className="user-settings-container">
            <Row>
                <Col md className="user-settings-details-col form-col me-3 mt-3">
                    <Form onSubmit={handleSubmitDetails} validated={validatedDetails} noValidate>
                        <h1>My Details</h1>
                        {inputs.map((input) => (
                            <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange} disabledValue={originalValues[input.name]} setValue={newValue => {setValues({...values, [input.name]:newValue})}}/>
                        ))}
                        <Button variant="primary" type="submit">
                            Submit Changes
                        </Button>
                        <Alert variant={"danger"} className="error-message mt-3">{errorMessageDetails}</Alert>
                        <Alert variant={"success"} className="success-message mt-3">{successMessageDetails}</Alert>
                    </Form>
                </Col>
                <Col md className="user-settings-password-col form-col mt-3">
                    <Form onSubmit={handleSubmitPassword} validated={validatedPass} noValidate>
                        <h1>Change Password</h1>
                        {passwordInputs.map((input) => (
                            <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
                        ))}
                        <Button variant="primary" type="submit">
                            Change Password
                        </Button>
                        <Alert variant={"danger"} className="error-message mt-3">{errorMessagePass}</Alert>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default UserSettings;